/* eslint-disable */
import React from 'react';
import './index.css';
// import '@editorjs/editorjs/dist/editor.css';
import App from './App';
// import '../node_modules/bootstrap/dist/css/bootstrap.css'
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { ProSidebarProvider } from 'react-pro-sidebar';
import * as te from 'tw-elements';

// if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
// }


const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <ProSidebarProvider >
        <App />
      </ProSidebarProvider>
    </Provider>
  // </React.StrictMode>
);