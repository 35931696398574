import React, { useEffect, useState } from "react";
import AutomationService from "../../../services/automation.service";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setAssessmentMetadata } from "../../../slices/assessment";

import checkImg from "../../../../assets/recruitment-images/check.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {} from "@fortawesome/free-regular-svg-icons";
import {
  faCircle,
  faCircleDot,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import getLocaltoUTCDatetime from "../../../utils/getLocalToUTCDatetime";
import getUTCToLocalDatetime from "../../../utils/getUTCToLocalDatetime";

import useLocalStorageListener from "../../../hooks/useLocalStorageListener";
import RoundDetail from "./RoundDetail";

function renderRoundDetail(
  rounds,
  userRoundData,
  handleOpenAssessment,
  recruitmentData,
  currentUser,
  setIsRejected,
  automationId
) {
  let currentRound = null;

  // Safely access component_schedule
  const componentSchedule = Array.isArray(userRoundData?.component_schedule)
    ? userRoundData.component_schedule
    : [];

  return rounds?.map((round, index) => {
    console.log(round);
    let assessmentId = 0;
    let statusNew = userRoundData?.user_data?.recruitment_status;
    let curDate = new Date();

    console.log(userRoundData);

    for (let i = 0; i < (round?.component_unit?.length || 0); i++) {
      if (round?.component_unit[i]?.unit_type === "Assessment") {
        assessmentId = round?.component_unit[i]?.assessment_id;
      }
    }

    let response_id = userRoundData?.user_data?.rounds?.find(
      (e) => e?.round === round?.component_id
    )?.response_id;

    let recruitment_status;
    let round_status = userRoundData?.user_data?.rounds?.find(
      (e) => e?.round === round?.component_id
    )?.status;

    let prevRoundComponentId = index > 0 ? rounds[index - 1]?.component_id : -1;
    let prevRoundStatus = "";
    if (index > 0) {
      prevRoundStatus = userRoundData?.user_data?.rounds?.find(
        (e) => e?.round === prevRoundComponentId
      )?.status;
    }

    const vcRoundData = userRoundData?.user_data.rounds.find((r) => {
      return r.round === round.component_id;
    });

    let startDate =
      round.component_type === "video-conf"
        ? vcRoundData?.start_date_time
        : componentSchedule.find((e) => e.component_id === round?.component_id)
            ?.component_start_date_time;
    let inFormatStartDate = startDate
      ? new Date(
          getUTCToLocalDatetime({
            datetime: startDate,
            format: "YYYY-MM-DDTHH:mm",
          })
        )
      : "";

    let endDate =
      round.component_type === "video-conf"
        ? vcRoundData?.end_date_time
        : componentSchedule.find((e) => e.component_id === round.component_id)
            ?.component_end_date_time;

    let inFormatEndDate = endDate
      ? new Date(
          getUTCToLocalDatetime({
            datetime: endDate,
            format: "YYYY-MM-DDTHH:mm",
          })
        )
      : "";

    // Rule 6: Check previous round status
    if (index > 0 && prevRoundStatus !== "Selected") {
      return null;
    }

    if (!startDate) {
      const status = "Not Scheduled";
      const button = "Start Disabled";
      return (
        <RoundDetail
          key={index}
          index={index}
          round={round}
          status={status}
          button={button}
          totalRounds={rounds?.length}
          response_id={response_id}
          handleOpenAssessment={handleOpenAssessment}
          assessmentId={assessmentId}
          fname={currentUser.fname}
          email={recruitmentData.email}
        />
      );
    }

    if (curDate < inFormatStartDate) {
      // Rule 1: Upcoming
      const status = "Upcoming";
      const button = "Start Disabled";
      return (
        <RoundDetail
          key={index}
          index={index}
          round={round}
          status={status}
          button={button}
          totalRounds={rounds?.length}
          response_id={response_id}
          handleOpenAssessment={handleOpenAssessment}
          assessmentId={assessmentId}
          fname={currentUser.fname}
          email={recruitmentData.email}
          startDate={inFormatStartDate}
          endDate={inFormatEndDate}
          componentType={round.component_type}
          link={vcRoundData?.link}
        />
      );
    } else if (curDate >= inFormatStartDate && curDate <= inFormatEndDate) {
      // Rule 1: Ongoing
      if (response_id) {
        // Rule 3: Completed
        const status = round_status;
        const button = "Completed";

        return (
          <RoundDetail
            key={index}
            index={index}
            round={round}
            status={status}
            button={button}
            totalRounds={rounds?.length}
            response_id={response_id}
            handleOpenAssessment={handleOpenAssessment}
            assessmentId={assessmentId}
            fname={currentUser.fname}
            email={recruitmentData.email}
            startDate={startDate}
            endDate={endDate}
            componentType={round.component_type}
            link={vcRoundData?.link}
          />
        );
      } else {
        const status = "Ongoing";
        const button = "Start Active";
        currentRound = round;
        return (
          <RoundDetail
            key={index}
            index={index}
            round={round}
            status={status}
            button={button}
            totalRounds={rounds?.length}
            response_id={response_id}
            handleOpenAssessment={handleOpenAssessment}
            assessmentId={assessmentId}
            fname={currentUser.fname}
            email={recruitmentData.email}
            startDate={startDate}
            endDate={endDate}
            componentType={round.component_type}
            link={vcRoundData?.link}
          />
        );
      }
    } else {
      // Rule 1: Past EndDateTime
      if (response_id) {
        // Rule 3: Completed
        const status = round_status;
        const button = "Completed";
        return (
          <RoundDetail
            key={index}
            index={index}
            round={round}
            status={status}
            button={button}
            totalRounds={rounds?.length}
            response_id={response_id}
            handleOpenAssessment={handleOpenAssessment}
            assessmentId={assessmentId}
            fname={currentUser.fname}
            email={recruitmentData.email}
            startDate={startDate}
            endDate={endDate}
            componentType={round.component_type}
            link={vcRoundData?.link}
          />
        );
      } else {
        // Rule 3: Missing Response
        const status = "Missed";
        const button = "Missing";
        const rejectUser = async () => {
          if (statusNew !== "Rejected") {
            statusNew = "Rejected";
            console.log({
              automation_id: automationId,
              round: round.component_id,
              institute_name: recruitmentData.institute,
              user_id: currentUser.user_id,
              status: "Rejected",
            });

            try {
              await AutomationService.changeSelectionStatus({
                automation_id: automationId,
                round: round.component_id,
                institute_name: recruitmentData.institute,
                user_id: currentUser.user_id,
                status: "Rejected",
              });
            } catch (error) {
              console.error("Error changing selection status:", error);
            }
            return true;
          }
        };

        rejectUser();
        return (
          <RoundDetail
            key={index}
            index={index}
            round={round}
            status={status}
            button={button}
            totalRounds={rounds?.length}
            response_id={response_id}
            handleOpenAssessment={handleOpenAssessment}
            assessmentId={assessmentId}
            fname={currentUser.fname}
            email={recruitmentData.email}
            startDate={startDate}
            endDate={endDate}
            componentType={round.component_type}
            link={vcRoundData?.link}
          />
        );
      }
    }
  });
}

export default function RegisteredView({
  recruitmentData,
  setRecruitmentData,
  currentUser,
  automationId,
  registrationStatus,
}) {
  const dispatch = useDispatch();
  const metadata = useSelector((state) => state.assessment.assessmentMetadata);
  const { user } = useSelector((state) => state.auth);
  const assessmentState = useLocalStorageListener("assessmentState");

  const [rounds, setRounds] = useState([]);
  const [userRoundData, setUserRoundData] = useState({
    component_schedule: [], // Initialize as empty array
    user_data: {
      rounds: [],
      recruitment_status: "",
      // ... other default properties if necessary
    },
  });
  const [loading, setLoading] = useState(true);
  const [isRejected, setIsRejected] = useState({
    status: false,
    component: "",
  });

  const handleOpenAssessment = ({
    assessmentId,
    template_id,
    fname,
    email,
    component_id,
  }) => {
    dispatch(
      setAssessmentMetadata({
        response_for_type: "recruitment",
        institute_name: recruitmentData?.institute,
        assessment_id: assessmentId,
        is_completed: false,
        template_id: template_id,
        fname: fname,
        email: email,
        automation_id: automationId,
        round: component_id,
      })
    );

    window.open(`/assessment/${assessmentId}`, "_blank");
  };

  const getRounds = async () => {
    setLoading(true);

    try {
      // Fetch component and user data
      const components = await AutomationService.getAllComponents({
        automation_id: automationId,
      });

      const userData = await AutomationService.getSingleUserDetail({
        automation_id: automationId,
        institute_name: recruitmentData.institute,
        user_id: currentUser.user_id,
      });

      console.log(userData.data);

      setUserRoundData(userData.data);

      const componentsMap = {};
      for (const component of components.data) {
        componentsMap[component.component_id] = component;
      }

      const matchingComponents = [];
      const nonMatchingComponents = [];

      // Validate component_schedule
      const componentSchedule = Array.isArray(userData.data.component_schedule)
        ? userData.data.component_schedule
        : [];

      for (const scheduleObj of componentSchedule) {
        const component = componentsMap[scheduleObj.component_id];
        if (component) {
          matchingComponents.push(component);
        }
      }

      for (const component of components.data) {
        if (!matchingComponents.includes(component)) {
          nonMatchingComponents.push(component);
        }
      }

      const sortedComponents = matchingComponents.concat(nonMatchingComponents);

      setRounds(sortedComponents);
    } catch (error) {
      console.error("Error fetching rounds:", error);
      // Optionally, setRounds to empty array or show an error message
      setRounds([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRounds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Logic to handle changes in assessmentState
    if (
      assessmentState &&
      assessmentState.assessmentMetadata &&
      assessmentState.assessmentMetadata.is_completed
    ) {
      console.log(
        "Assessment completion status changed. Refreshing component..."
      );

      getRounds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentState]);

  console.log(currentUser?.profile_image);

  const renderedRounds = renderRoundDetail(
    rounds,
    userRoundData,
    handleOpenAssessment,
    recruitmentData,
    currentUser,
    setIsRejected,
    automationId
  );
  const defaultImage =
    "https://img.freepik.com/free-vector/illustration-businessman_53876-5856.jpg?w=740&t=st=1705223161~exp=1705223761~hmac=1b2cb13a4492ec7357c3e9a42f10e7574d8ae81d9f629ac19909c924aa774c64";
  return (
    <>
      {loading ? (
        <>Loading</>
      ) : (
        <div className="my-8">
          <div className="rounded-lg mt-4 bg-zinc-100 p-3 border border-1">
            <div className="grid grid-cols-12">
              <div className="col-span-12 md:col-span-8 flex justify-start items-center">
                <div className="w-32 h-full flex justify-center items-center">
                  <img
                    className="w-24 h-24 rounded-full object-cover"
                    alt=""
                    src={
                      currentUser?.profile_image
                        ? currentUser.profile_image
                        : defaultImage
                    }
                  />
                </div>
                <div className="p-2">
                  <div className="flex justify-start items-center">
                    <div className="text-lg md:text-xl lg:text-3xl text-dark py-2 mr-3">
                      {currentUser.fname} {currentUser.lname}
                    </div>
                    {rounds.length > 0 && (
                      <Link
                        className="no-underline"
                        to={`/report/recruitment/${currentUser.user_id}/${rounds[0]?.component_id}`}
                      >
                        <div className="p-2 text-md w-8 h-8 rounded-full border-2 bg-white border-dark cursor-pointer shadow-md flex justify-center items-center">
                          <FontAwesomeIcon icon={faUser} />
                        </div>
                      </Link>
                    )}
                  </div>
                  <div className="w-full text-base font-semibold">
                    {recruitmentData?.institute}
                  </div>
                  <div className="w-full text-sm mt-2 flex justify-start items-center">
                    <div className="px-1">{recruitmentData?.student_id}</div>
                    <div className="text-slate-400 px-1">|</div>
                    <div className="px-1">{recruitmentData?.email}</div>
                  </div>
                  <div className="mt-2">
                    <div
                      className={
                        recruitmentData.status === "Selected"
                          ? "w-fit px-2 py-1 text-xs text-white rounded-md bg-green-500"
                          : recruitmentData.status === "Ongoing"
                          ? "w-fit py-1 text-xs text-white rounded-md bg-amber-500 px-2"
                          : "w-fit py-1 text-xs text-white rounded-md bg-red-500 px-2"
                      }
                    >
                      {recruitmentData.status}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-4">
                <div className="w-full h-full flex justify-center items-center text-2xl md:text-4xl lg:text-5xl font-semibold mx-4">
                  <span className="text-blue-500">UP</span>
                  <span className="text-green-500">MY</span>
                  <span className="text-amber-400">SKILL</span>
                  {/* <img
                    className="w-full h-full object-cover"
                    alt=""
                    src={checkImg}
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-[1000px] mx-auto mt-10">
            <div className="text-base">
              Let's get started with the Recruitment process!
            </div>
            <div className="">
              <div className="w-full">
                {renderedRounds.length > 0 ? (
                  renderedRounds
                ) : (
                  <div>No rounds available at the moment.</div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}


















// import React, { useEffect, useState } from "react";
// import AutomationService from "../../../services/automation.service";
// import { Link } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import { setAssessmentMetadata } from "../../../slices/assessment";

// import checkImg from "../../../../assets/recruitment-images/check.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {} from "@fortawesome/free-regular-svg-icons";
// import {
//   faCircle,
//   faCircleDot,
//   faUser,
// } from "@fortawesome/free-solid-svg-icons";

// import getLocaltoUTCDatetime from "../../../utils/getLocalToUTCDatetime";
// import getUTCToLocalDatetime from "../../../utils/getUTCToLocalDatetime";

// import useLocalStorageListener from "../../../hooks/useLocalStorageListener";
// import RoundDetail from "./RoundDetail";

// function renderRoundDetail(
//   rounds,
//   userRoundData,
//   handleOpenAssessment,
//   recruitmentData,
//   currentUser,
//   setIsRejected,
//   automationId
// ) {
//   let currentRound = null;

//   return rounds?.map((round, index) => {
//     // console.log(round)
//     let assessmentId = 0;
//     let statusNew = userRoundData?.user_data?.recruitment_status;
//     let curDate = new Date();

//     // console.log(userRoundData)

//     for (let i = 0; i < round?.component_unit?.length; i++) {
//       if (round?.component_unit[i]?.unit_type === "Assessment") {
//         assessmentId = round?.component_unit[i]?.assessment_id;
//       }
//     }

//     let response_id = userRoundData?.user_data?.rounds?.find(
//       (e) => e?.round === round?.component_id
//     )?.response_id;

//     let recruitment_status;
//     let round_status = userRoundData?.user_data?.rounds?.find(
//       (e) => e?.round === round?.component_id
//     )?.status;

//     let prevRoundComponentId = index > 0 ? rounds[index - 1]?.component_id : -1;
//     let prevRoundStatus = "";
//     if (index > 0) {
//       prevRoundStatus = userRoundData?.user_data?.rounds?.find(
//         (e) => e?.round === prevRoundComponentId
//       )?.status;
//     }

//     const vcRoundData = userRoundData?.user_data.rounds.find((r)=>{
//       return r.round === round.component_id
//     })

//     let startDate = round.component_type === "video-conf" ? vcRoundData?.start_date_time : userRoundData?.component_schedule?.find(
//       (e) => e.component_id === round?.component_id
//     )?.component_start_date_time;
//     let inFormatStartDate = startDate
//       ? new Date(
//           getUTCToLocalDatetime({
//             datetime: startDate,
//             format: "YYYY-MM-DDTHH:mm",
//           })
//         )
//       : "";

//     let endDate = round.component_type === "video-conf" ? vcRoundData?.end_date_time : userRoundData?.component_schedule?.find(
//       (e) => e.component_id === round.component_id
//     )?.component_end_date_time;

//     // let inFormatEndDate = new Date(endDate);
//     let inFormatEndDate = endDate
//       ? new Date(
//           getUTCToLocalDatetime({
//             datetime: endDate,
//             format: "YYYY-MM-DDTHH:mm",
//           })
//         )
//       : "";
//     // Rule 6: Until and unless status of previous round = "Selected" never render next round
//     if (index > 0 && prevRoundStatus !== "Selected") {
//       return null;
//     }
//     // Rule 9: Check for startDateTime existence

//     // Rule 6: Until and unless status of previous round = "Selected" never render next round
//     if (index > 0 && prevRoundStatus !== "Selected") {
//       return null;
//     }

//     if (!startDate) {
//       const status = "Not Scheduled";
//       const button = "Start Disabled";
//       return (
//         <RoundDetail
//           key={index}
//           index={index}
//           round={round}
//           status={status}
//           button={button}
//           totalRounds={rounds?.length}
//           response_id={response_id}
//           handleOpenAssessment={handleOpenAssessment}
//           assessmentId={assessmentId}
//           fname={currentUser.fname}
//           email={recruitmentData.email}
//         />
//       );
//     }

//     if (curDate < inFormatStartDate) {
//       // Rule 1: If currentDateTime < startDatetime, button = "Start Disabled", status = "Upcoming"
//       const status = "Upcoming";
//       const button = "Start Disabled";
//       return (
//         <RoundDetail
//           key={index}
//           index={index}
//           round={round}
//           status={status}
//           button={button}
//           totalRounds={rounds?.length}
//           response_id={response_id}
//           handleOpenAssessment={handleOpenAssessment}
//           assessmentId={assessmentId}
//           fname={currentUser.fname}
//           email={recruitmentData.email}
//           startDate={inFormatStartDate}
//           endDate={inFormatEndDate}
//           componentType={round.component_type}
//           link={vcRoundData?.link}
//         />
//       );
//     } else if (curDate >= inFormatStartDate && curDate <= inFormatEndDate) {
//       // Rule 1: If currentDateTime > startDatetime and currentDateTime < EndDateTime, button = "Start Active", status = "Ongoing"

//       if (response_id) {
//         // Rule 3: If response is present, button = "Completed"
//         const status = round_status;
//         const button = "Completed";
   
//         return (
//           <RoundDetail
//             key={index}
//             index={index}
//             round={round}
//             status={status}
//             button={button}
//             totalRounds={rounds?.length}
//             response_id={response_id}
//             handleOpenAssessment={handleOpenAssessment}
//             assessmentId={assessmentId}
//             fname={currentUser.fname}
//             email={recruitmentData.email}
//             startDate={startDate}
//             endDate={endDate}
//             componentType={round.component_type}
//             link={vcRoundData?.link}
//           />
//         );
//       } else {
//         const status = "Ongoing";
//         const button = "Start Active";
//         currentRound = round;
//         return (
//           <RoundDetail
//             key={index}
//             index={index}
//             round={round}
//             status={status}
//             button={button}
//             totalRounds={rounds?.length}
//             response_id={response_id}
//             handleOpenAssessment={handleOpenAssessment}
//             assessmentId={assessmentId}
//             fname={currentUser.fname}
//             email={recruitmentData.email}
//             startDate={startDate}
//             endDate={endDate}
//             componentType={round.component_type}
//             link={vcRoundData?.link}
//           />
//         );
//       }
//     } else {
//       // Rule 1: If currentDateTime > EndDateTime

//       if (response_id) {
//         // Rule 3: If response is present, button = "Completed"
//         const status = round_status;
//         const button = "Completed";
//         return (
//           <RoundDetail
//             key={index}
//             index={index}
//             round={round}
//             status={status}
//             button={button}
//             totalRounds={rounds?.length}
//             response_id={response_id}
//             handleOpenAssessment={handleOpenAssessment}
//             assessmentId={assessmentId}
//             fname={currentUser.fname}
//             email={recruitmentData.email}
//             startDate={startDate}
//             endDate={endDate}
//             componentType={round.component_type}
//             link={vcRoundData?.link}
//           />
//         );
//       } else {
//         // Rule 3: If response is missing, button = "Start Disabled", recruitment_status = "Rejected"
//         const status = "Missed";
//         const button = "Missing";
//         const rejectUser = async () => {
       
//           if (statusNew !== "Rejected") {
//             //getInstitute
//             statusNew = "Rejected";
//             // console.log({
//             //   automation_id: automationId,
//             //   round: round.component_id,
//             //   institute_name: recruitmentData.institute,
//             //   user_id: currentUser.user_id,
//             //   status: "Rejected"
//             // })
//             // console.log(recruitmentData.institute, "institute", automationId, userRoundData.user_data.user_id,round.component_id, )
//             await AutomationService.changeSelectionStatus({
//               automation_id: automationId,
//               round: round.component_id,
//               institute_name: recruitmentData.institute,
//               user_id: currentUser.user_id,
//               status: "Rejected",
//             });
//             return true;
//           }
//         };

//         // rejectUser();
//         return (
//           <RoundDetail
//             key={index}
//             index={index}
//             round={round}
//             status={status}
//             button={button}
//             totalRounds={rounds?.length}
//             response_id={response_id}
//             handleOpenAssessment={handleOpenAssessment}
//             assessmentId={assessmentId}
//             fname={currentUser.fname}
//             email={recruitmentData.email}
//             startDate={startDate}
//             endDate={endDate}
//             componentType={round.component_type}
//             link={vcRoundData?.link}
//           />
//         );
//       }
//     }
//   });

//   // Rule 8: If round_status="Pending" and curDateTime > EndDateTime for next round, change round_status="Rejected" and recruitment_status="Rejected".
//   // if (
//   //   currentRound &&
//   //   currentRound.round_status === "Pending" &&
//   //   currentDateTime > rounds[rounds.indexOf(currentRound) + 1].endDateTime
//   // ) {
//   //   rounds[rounds.indexOf(currentRound) + 1].round_status = "Rejected";
//   //   rounds[rounds.indexOf(currentRound) + 1].recruitment_status = "Rejected";
//   // }

//   // console.log(rounds[rounds.indexOf(currentRound) + 1])
// }

// export default function RegisteredView({
//   recruitmentData,
//   setRecruitmentData,
//   currentUser,
//   automationId,
//   registrationStatus,
// }) {
//   const dispatch = useDispatch();
//   const metadata = useSelector((state) => state.assessment.assessmentMetadata);
//   const { user } = useSelector((state) => state.auth);
//   const assessmentState = useLocalStorageListener("assessmentState");

//   const [rounds, setRounds] = useState();
//   const [userRoundData, setUserRoundData] = useState();
//   const [loading, setLoading] = useState(true);
//   const [isRejected, setIsRejected] = useState({
//     status: false,
//     component: "",
//   });

//   const handleOpenAssessment = ({
//     assessmentId,
//     template_id,
//     fname,
//     email,
//     component_id,
//   }) => {
//     dispatch(
//       setAssessmentMetadata({
//         response_for_type: "recruitment",
//         institute_name: recruitmentData?.institute,
//         assessment_id: assessmentId,
//         is_completed: false,
//         template_id: template_id,
//         fname: fname,
//         email: email,
//         automation_id: automationId,
//         round: component_id,
//       })
//     );

//     window.open(`/assessment/${assessmentId}`, "_blank");
//   };

//   const getRounds = async () => {
//     setLoading(true);

//     //component round data
//     const components = await AutomationService.getAllComponents({
//       automation_id: automationId,
//     });

//     const userData = await AutomationService.getSingleUserDetail({
//       automation_id: automationId,
//       institute_name: recruitmentData.institute,
//       user_id: currentUser.user_id,
//     });
    
//     setUserRoundData(userData.data);

//     const componentsMap = {};
//     for (const component of components.data) {
//       componentsMap[component.component_id] = component;
//     }

//     const matchingComponents = [];
//     const nonMatchingComponents = [];

//     for (const scheduleObj of userData.data.component_schedule) {
//       const component = componentsMap[scheduleObj.component_id];
//       if (component) {
//         matchingComponents.push(component);
//       }
//     }

//     for (const component of components.data) {
//       if (!matchingComponents.includes(component)) {
//         nonMatchingComponents.push(component);
//       }
//     }

//     const sortedComponents = matchingComponents.concat(nonMatchingComponents);

//     // setRounds(components.data);
//     setRounds(sortedComponents)


//     setLoading(false);
//   };

//   useEffect(() => {
//     getRounds();
//   }, []);

//   useEffect(() => {
//     // Logic to handle changes in assessmentState
//     if (
//       assessmentState &&
//       assessmentState.assessmentMetadata &&
//       assessmentState.assessmentMetadata.is_completed
//     ) {
//       // console.log(
//       //   "Assessment completion status changed. Refreshing component..."
//       // );

//       getRounds();
//     }
//   }, [assessmentState]);

//   // console.log(currentUser?.profile_image);

//   const renderedRounds = renderRoundDetail(
//     rounds,
//     userRoundData,
//     handleOpenAssessment,
//     recruitmentData,
//     currentUser,
//     setIsRejected,
//     automationId
//   );
//   let defaultImage =
//     "https://img.freepik.com/free-vector/illustration-businessman_53876-5856.jpg?w=740&t=st=1705223161~exp=1705223761~hmac=1b2cb13a4492ec7357c3e9a42f10e7574d8ae81d9f629ac19909c924aa774c64";
//   return (
//     <>
//       {loading ? (
//         <>Loading</>
//       ) : (
//         <div className="my-8">
//           <div className="  rounded-lg mt-4 bg-zinc-100 p-3 border border-1">
//             <div className=" grid grid-cols-12">
//               <div className="col-span-12 md:col-span-8 flex justify-start items-center">
//                 <div className="w-32 h-full flex justify-center items-center ">
//                   <img
//                     className=" w-24 h-24 rounded-full object-cover"
//                     alt=""
//                     src={
//                       currentUser?.profile_image
//                         ? currentUser.profile_image
//                         : defaultImage
//                     }
//                   />
//                 </div>
//                 <div className="p-2 ">
//                   <div className="flex justify-start items-center">
//                     <div className=" text-lg md:text-xl lg:text-3xl text-dark py-2 mr-3">
//                       {currentUser.fname} {currentUser.lname}
//                     </div>
//                     <Link
//                       className=" no-underline"
//                       to={`/report/recruitment/${currentUser.user_id}/${rounds[0]?.component_id}`}
//                     >
//                       <div className="p-2 text-md w-8 h-8 rounded-full border-2 bg-white  border-dark cursor-pointer shadow-md  flex justify-center items-center">
//                         <FontAwesomeIcon icon={faUser} />
//                       </div>
//                     </Link>
//                   </div>
//                   <div className="w-full text-base font-semibold ">
//                     {recruitmentData?.institute}
//                   </div>
//                   <div className="w-full text-sm mt-2 flex justify-start items-center  ">
//                     <div className="px-1 ">{recruitmentData?.student_id}</div>
//                     <div className="text-slate-400 px-1">|</div>
//                     <div className="px-1">{recruitmentData?.email}</div>
//                   </div>
//                   <div className="mt-2">
//                     <div
//                       className={
//                         recruitmentData.status === "Selected"
//                           ? "w-fit px-2 py-1 text-xs text-white rounded-md bg-green-500  "
//                           : recruitmentData.status === "Ongoing"
//                           ? "w-fit  py-1 text-xs text-white rounded-md bg-amber-500  px-2 "
//                           : "w-fit  py-1 text-xs text-white rounded-md bg-red-500  px-2 "
//                       }
//                     >
//                       {recruitmentData.status}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-span-12 md:col-span-4">
//                 <div className="w-full h-full flex justify-center items-center text-2xl md:text-4xl lg:text-5xl font-semibold mx-4">
//                   <span className="text-blue-500">UP</span>
//                   <span className="text-green-500">MY</span>
//                   <span className="text-amber-400">SKILL</span>
//                   {/* <img
//                     className="w-full h-full object-cover"
//                     alt=""
//                     src={checkImg}
//                   /> */}
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="max-w-[1000px] mx-auto mt-10">
//             <div className=" text-base">
//               Let's get started with the Recruitment process!
//             </div>
//             <div className=" ">
//               <div className="w-full">{renderedRounds}</div>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// }
