import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/new-logo.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faUser } from "@fortawesome/free-regular-svg-icons";
import { Navigate } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useCallback } from "react";
import { logout } from "../slices/auth";

export default function NewNavigationBar() {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [dropdown, setDropdown] = useState(false);

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (!currentUser) {
      navigate("/sign-in");
    }
  }, [currentUser]);

  let menus = ["Change Password", "Logout"];

  // console.log(currentUser, "Resux user state");
  return (
    <div className="w-full fixed poppins z-50">
      <div className=" h-14 bg-white shadow-sm">
        <div className="w-full h-full flex items-center">
          <div className="flex basis-2/6 justify-start items-center px-2 cursor-pointer"
          onClick={()=>{
            navigate("/")
          }}>
            <img
              loading="lazy"
              src={Logo}
              className="w-96 h-12 object-contain"
              alt="Logo"
            />
          </div>
          <div className="flex basis-3/6 justify-end items-center px-2"></div>
          <div className="flex basis-1/6 justify-end items-center px-2">
            {/* <div className=" px-1 mx-1 text-xl h-10 w-10 rounded-full cursor-pointer hover:bg-white flex justify-center items-center">
              <FontAwesomeIcon icon={faBell} />
            </div> */}
            <div
              className="px-1 mx-1 h-10 w-10 rounded-full text-xl cursor-pointer bg-white flex justify-center items-center  relative"
              onClick={() => {
                setDropdown(!dropdown);
              }}
            >
              <FontAwesomeIcon icon={faUser} />
              {dropdown ? (
                <div className=" absolute top-12 right-0 w-40 bg-white rounded-md shadow-lg z-20">
                  {menus.map((e, index) => {
                    return (
                      <div
                        className="py-2 px-3 m-2 rounded-md  text-sm cursor-pointer hover:bg-blue-200"
                        onClick={() => {
                          if (e === "Logout") {
                            logOut();
                            navigate("/sign-in");
                          } else if (e === "Change Password") {
                            navigate("/change-password");
                          } else {
                            navigate(`/profile/${currentUser.user_id}`);
                          }
                        }}
                      >
                        {e}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <></>
              )}
              {/* <img
                className=" w-full h-full rounded-full object-cover"
                src={currentUser?.profile_image}
                alt=""/> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
