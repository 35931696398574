import { faCircle, faCircleDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

import getLocaltoUTCDatetime from "../../../utils/getLocalToUTCDatetime";
import getUTCToLocalDatetime from "../../../utils/getUTCToLocalDatetime";

import {toast} from "react-toastify"

export default function RoundDetail({
  status,
  button,
  index,
  totalRounds,
  round,
  response_id,
  handleOpenAssessment,
  assessmentId,
  fname,
  email,
  startDate,
  endDate,
  componentType,
  link
}) {
  function formatUrl(link) {
    if (!link.startsWith('http://') && !link.startsWith('https://')) {
      return 'http://' + link;
    }
    return link;
  }

  return (
    <div>
      <div className="w-full relative mt-4">
        <div className="text-lg absolute -top-2 -left-2 text-blue-600">
          {status === "Pending" ||
          status === "Selected" ||
          status === "Rejected" ||
          status === "Missed" ? (
            <FontAwesomeIcon icon={faCircle} />
          ) : (
            <FontAwesomeIcon icon={faCircleDot} />
          )}
        </div>
        <div className="px-2 border-l-2 border-dotted ">
          <div className="  border-2 rounded-md  p-3 ml-6">
            <div className="flex justify-start items-center">
              <div className="text-xs">
                STEP - {index + 1} 
                {/* / {totalRounds} */}
              </div>
              <div className=" ml-2">
                <div className=" px-2 text-base">
                  {status === "Upcoming" ? (
                    <div className="px-3 py-2 bg-blue-100  text-blue-700 shadow-sm cursor-pointer rounded-full">
                      Upcoming
                    </div>
                  ) : status === "Pending" ? (
                    <div className="px-3 py-2 bg-orange-100   text-orange-700 shadow-sm cursor-pointer rounded-full">
                      Pending
                    </div>
                  ) : status === "Selected" ? (
                    <div className="px-3 py-2 bg-green-100   text-green-700 shadow-sm cursor-pointer rounded-full">
                      Selected
                    </div>
                  ) : status === "Rejected" ? (
                    <div className="px-3 py-2 bg-red-100  text-red-700 shadow-sm cursor-pointer rounded-full">
                      Rejected
                    </div>
                  ) : status === "Missed" ? (
                    <div className="px-3 py-2 bg-slate-200   text-slate-500 shadow-sm cursor-pointer rounded-full">
                      Missing
                    </div>
                  ) : status === "Ongoing" ? (
                    <div className="px-3 py-2 bg-amber-100   text-amber-700 shadow-sm cursor-pointer rounded-full">
                      Ongoing
                    </div>
                  ) : (
                    <div className="px-2 py-1 bg-slate-200   text-dark shadow-sm cursor-pointer rounded-full">
                      Not Initiated
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex mt-2 justify-between items-center">
              <div className="text-lg md:text-xl">{round?.component_name}</div>
              <div className="flex  item-center basis-3/12 justify-end">
                {button === "Start Active" || button === "Completed" ? (
                  <>
                    {response_id ? (
                      <>
                        <Link
                          // to={`/report/recruitment/${currentUser?.user_id}/${round.component_id}`}
                          className=" no-underline"
                        >
                          <div className="px-3 py-2 bg-green-500  text-sm text-white shadow-sm  rounded-full">
                            Completed
                          </div>
                        </Link>
                      </>
                    ) : (
                      <div
                        onClick={() => {
                          if(componentType == "video-conf"){
                            if(link){
                              const formattedLink = formatUrl(link);
                              window.open(formattedLink,"_blank")
                            }
                            else{
                              toast.info("Not Available at the moment")
                            }
                          }else{
                            handleOpenAssessment({
                              assessmentId: assessmentId,
                              template_id: round?.component_unit.find((unit) => {
                                return unit?.unit_type === "Completion Email";
                              })?.unit_id,
                              // fname: user.fname,
                              // email: recruitmentData?.email,
                              fname: fname,
                              email: email,
                              component_id: round?.component_id,
                            });
                          }
                        }}
                      >
                        <div className="px-3 py-2 bg-blue-500 hover:bg-blue-700 text-sm text-white shadow-sm cursor-pointer rounded-full">
                          {/* Start */}
                          {componentType === "video-conf" ? "Join" : "Start"}
                        </div>
                        {/* </Link> */}
                      </div>
                    )}
                  </>
                ) : button === "Start Disabled" ? (
                  <div className="px-3 py-2 bg-slate-200  text-sm text-slate-600 shadow-sm  rounded-full cursor-not-allowed">
                    {/* Start */}
                    {componentType === "video-conf" ? "Join" : "Start"}
                  </div>
                ) : button === "Missing" ? (
                  <div className="px-3 py-2 bg-red-400  text-sm text-white shadow-sm  rounded-full cursor-not-allowed">
                    Missed
                  </div>
                ) : (
                  <></>
                )}
                {/* <Link to={`/assessment/${assessmentId}`} className="no-underline" target="_blank"> */}
              </div>
            </div>

            <div className="flex mt-2 justify-between items-center">
              <div className="text-lg md:text-xl">{`Start: ${
                getUTCToLocalDatetime({datetime: startDate,
                format: "DD MMM, YYYY hh:mmA"})}`}</div>
              <div className="text-lg md:text-xl">{`End:  ${
                getUTCToLocalDatetime({datetime: endDate,
                format: "DD MMM, YYYY hh:mmA"})}`}</div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
